import React, { useState } from "react";
//PRIMER ITEM
import Pintecord from "../../../IMAGES/Recurso 15.png";
import ALEMDESING  from "../../../IMAGES/Recurso 11.png";
import ELECTROALEM from "../../../IMAGES/Recurso 13.png";
import Iris from "../../../IMAGES/Recurso 17.png";
//====================================================
import Pintecord2 from "../../../IMAGES/Recurso 15b.png";
import ALEMDESING2  from "../../../IMAGES/Recurso 11b.png";
import ELECTROALEM2 from "../../../IMAGES/Recurso 13b.png";
import Iris2 from "../../../IMAGES/Recurso 17b.png";

//SEGUNDO ITEM
import ESTUDIOROCCA from "../../../IMAGES/Recurso 18.png";
import PLEYADES from "../../../IMAGES/Recurso 24.png";
import ALDEADESUEÑOS from "../../../IMAGES/Recurso 1.png";
import CASADRUMA from "../../../IMAGES/Recurso 25.png";
import BLACKOVEJA from "../../../IMAGES/Recurso 2.png";
//====================================================
import ESTUDIOROCCA2 from "../../../IMAGES/Recurso 18b.png";
import PLEYADES2 from "../../../IMAGES/Recurso 24b.png";
import ALDEADESUEÑOS2 from "../../../IMAGES/Recurso 1b.png";
import CASADRUMA2 from "../../../IMAGES/Recurso 25b.png";
import BLACKOVEJA2 from "../../../IMAGES/Recurso 2b.png";

//TERCER ITEM
import CHENNA from "../../../IMAGES/Recurso 16.png";
import ICC from "../../../IMAGES/Recurso 5.png";
//====================================================
import CHENNA2 from "../../../IMAGES/Recurso 16b.png";
import ICC2 from "../../../IMAGES/Recurso 5b.png";
import OBRAHOME from "../../../IMAGES/Recurso 30.png"
import OBRAHOME2 from "../../../IMAGES/Recurso 30b.png"
import QuantityClicks from "../../../CurstomHooks/QuantityClicks";

const CarrouselDos = () => {
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");
  const [image9, setImage9] = useState("");
  const [image10, setImage10] = useState("");
  const [image11, setImage11] = useState(""); //Estado liberado
  const [image12, setImage12] = useState("");
  const [image13, setImage13] = useState("");
  const [image14, setImage14] = useState("");

  return (
    <div className="container-fluid mt-5 mb-3 border-top border-dark">
      <div
        id="carouselExample2"
        className="carousel carousel-dark slide py-5"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExample2"
            data-bs-slide-to="0"
            className="active"
            aria-current={true}
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExample2"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExample2"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        {/* PRIMERA FILA CARRUSEL */}
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2500">
            <div className="row py-5 py-sm-3 py-lg-2 text-center justify-content-center">
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Pinturas</p>
                <a
                  href="https://wa.me/3513224370"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://wa.me/3513224370")
                  }
                  onMouseDown={() => QuantityClicks("https://wa.me/3513224370")}
                >
                  <img
                    src={!image1 ? Pintecord : image1}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage1(Pintecord2)}
                    onMouseLeave={() => setImage1(Pintecord)}
                    alt="logo Pintecord"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Iluminación</p>
                <a
                  href="https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d")
                  }
                  onMouseDown={() => QuantityClicks("https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d")}
                >
                  <img
                    src={!image2 ? ALEMDESING : image2}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage2(ALEMDESING2)}
                    onMouseLeave={() => setImage2(ALEMDESING)}
                    alt="logo ALEMDESING"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Electricidad</p>
                <a
                  href="https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d")
                  }
                  onMouseDown={() => QuantityClicks("https://linktr.ee/Electro.Alem?utm_source=linktree_profile_share&ltsid=6ca5c094-0906-48b7-b72f-a80c497a948d")}
                >
                  <img
                    src={!image3 ? ELECTROALEM : image3}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage3(ELECTROALEM2)}
                    onMouseLeave={() => setImage3(ELECTROALEM)}
                    alt="logo ELECTROALEM"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Paneles Solares</p>
                <a
                  href="https://irisenergia.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://irisenergia.com.ar/")
                  }
                  onMouseDown={() => QuantityClicks("https://irisenergia.com.ar/")}
                >
                  <img
                    src={!image4 ? Iris : image4}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage4(Iris2)}
                    onMouseLeave={() => setImage4(Iris)}
                    alt="logo iris"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* SEGUNDA FILA CARRUSEL */}
          <div className="carousel-item" data-bs-interval="2500">
            <div className="row py-sm-2 text-center justify-content-center">
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Textiles</p>
                <a
                  href="https://instagram.com/estudio.rocca?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://instagram.com/estudio.rocca?igshid=MzRlODBiNWFlZA==")
                  }
                  onMouseDown={() => QuantityClicks("https://instagram.com/estudio.rocca?igshid=MzRlODBiNWFlZA==")}
                >
                  <img
                    src={!image6 ? ESTUDIOROCCA : image6}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage6(ESTUDIOROCCA2)}
                    onMouseLeave={() => setImage6(ESTUDIOROCCA)}
                    alt="logo las acasias"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Paisajismo</p>
                <a
                  href="https://instagram.com/pleyadespaisaje?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://instagram.com/pleyadespaisaje?igshid=MzRlODBiNWFlZA==")
                  }
                  onMouseDown={() => QuantityClicks("https://instagram.com/pleyadespaisaje?igshid=MzRlODBiNWFlZA==")}
                >
                  <img
                    src={!image7 ? PLEYADES  : image7}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage7(PLEYADES2)}
                    onMouseLeave={() => setImage7(PLEYADES)}
                    alt="loco PLEYADES"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Colchon y Almohadas</p>
                <a
                  href="https://www.instagram.com/aldeadesuenoscba/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://www.instagram.com/aldeadesuenoscba/")
                  }
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/aldeadesuenoscba/")}
                >
                  <img
                    src={!image8 ? ALDEADESUEÑOS : image8}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage8(ALDEADESUEÑOS2 )}
                    onMouseLeave={() => setImage8(ALDEADESUEÑOS)}
                    alt="logo ALDEADESUEÑOS"
                  />
                </a>
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Decoración</p>
                <a
                  href="https://instagram.com/casadruma?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://instagram.com/casadruma?igshid=MzRlODBiNWFlZA==")
                  }
                  onMouseDown={() => QuantityClicks("https://instagram.com/casadruma?igshid=MzRlODBiNWFlZA==")}
                >
                  <img
                    src={!image9 ? CASADRUMA : image9}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage9(CASADRUMA2)}
                    onMouseLeave={() => setImage9(CASADRUMA)}
                    alt="logo CASADRUMA"
                  />
                </a>
              </div>
     
            </div>
          </div>
          {/* TERCERA FILA CARRUSEL */}
          <div className="carousel-item" data-bs-interval="2500">
            <div className="row py-5 py-sm-2 text-center justify-content-center">
              <div className="col-sm-2 col-md-3 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Tapices</p>
                <a
                  href="https://instagram.com/blackoveja_ar?igshid=NzZlODBkYWE4Ng=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://instagram.com/blackoveja_ar?igshid=NzZlODBkYWE4Ng==")
                  }
                  onMouseDown={() => QuantityClicks("https://instagram.com/blackoveja_ar?igshid=NzZlODBkYWE4Ng==")}
                >
                  <img
                    src={!image10 ? BLACKOVEJA : image10}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage10(BLACKOVEJA2)}
                    onMouseLeave={() => setImage10(BLACKOVEJA)}
                    alt="logo ceramista"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-3 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Equipamiento</p>
                <a
                  href="https://www.instagram.com/obrahome/?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://www.instagram.com/obrahome/?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw==")
                  }
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/obrahome/?utm_source=ig_web_button_share_sheet&igshid=ZDNlZDc0MzIxNw==")}
                >
                  <img
                    src={!image11 ? OBRAHOME : image11}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage11(OBRAHOME2)}
                    onMouseLeave={() => setImage11(OBRAHOME)}
                    alt="logo beppo"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-3 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Accesorios</p>
                <a
                  href="https://www.instagram.com/chennaoficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://www.instagram.com/chennaoficial")
                  }
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/chennaoficial")}
                >
                  <img
                    src={!image12 ? CHENNA : image12}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage12(CHENNA2)}
                    onMouseLeave={() => setImage12(CHENNA)}
                    alt="logo CHENNA"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-3 col-lg-2 mx-lg-1">
                <p className="text-carrousel">Camaras de Seguridad</p>
                <a
                  href="https://www.instagram.com/marceloicc12/?igshid=NzZlODBkYWE4Ng%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    QuantityClicks("https://www.instagram.com/marceloicc12/?igshid=NzZlODBkYWE4Ng%3D%3D")
                  }
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/marceloicc12/?igshid=NzZlODBkYWE4Ng%3D%3D")}
                >
                  <img
                    src={!image13 ? ICC : image13}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage13(ICC2)}
                    onMouseLeave={() => setImage13(ICC)}
                    alt="logo ICC"
                  />
                </a>
              </div>
    
            </div>
          </div>
        </div>
        <button
          className=" carousel-control-prev w-btn-carrousel"
          type="button"
          data-bs-target="#carouselExample2"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon mr-carrousel-5"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next  w-btn-carrousel"
          type="button"
          data-bs-target="#carouselExample2"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon ml-carrousel-5"
            aria-hidden={true}
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CarrouselDos;
