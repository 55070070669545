import React from "react";
import Redirecciones from "../Redirecciones.json";
import { useEffect } from "react";
import QuantityClicks from "../../CurstomHooks/QuantityClicks";
import { useParams } from "react-router-dom";

const QR = () => {
  const { idQR } = useParams();
  let getIdUrl = Redirecciones.filter((d) => d.URL === `/${idQR}`);
  const url = getIdUrl[0].Direcciona;

  const postClick = () => {
    QuantityClicks(url);
  };

  useEffect(() => {
    postClick();
    window.location.replace(url);
  }, []);

  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row text-center">
          <div className="col-12 mb-5">
            <h1 className="display-3">Cargando QR</h1>
          </div>
          <div className="col-12">
            <div className="spinner morph"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QR;
