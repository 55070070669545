import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsWhatsapp,
  BsLayoutTextWindowReverse,
} from "react-icons/bs";
import QuantityClicks from "../../CurstomHooks/QuantityClicks"; 
import Whatsapp from "../../IMAGES/whatsapp.png"
import Instagram from "../../IMAGES/instagram.png"
import Facebook from "../../IMAGES/facebook.png"

const Footer = () => {
  return (
    <>
      <div className="container-fluid pt-md-2 pb-1 pt-2 pb-md-1 pt-lg-2 pt-xl-3 pb-xl-1 bg-footer">
        <p className="text-footer" scrollamount="18">
          ¡Te invitamos a descubrir y a vivir la Experiencia CASA EN BLANCO en
          nuestro Showroom!
        </p>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13627.268482245743!2d-64.2069918!3d-31.36402545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94329912a5d78153%3A0xd1fa81bb6f84a7eb!2sEdificor!5e0!3m2!1ses!2sar!4v1654007513077!5m2!1ses!2sar"
        width="100%"
        height="223px"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        title="Ubicacion edificor"
      ></iframe>
      <footer className="container-fluid bg-footer py-2 py-md-2 py-xl-2 mt-footer">
        <div className="row justify-content-center pt-lg-1 pt-xl-1">
          <div className="col-3 col-sm-2 col-md-1 col-lg-1 text-end">
            <a className="text-decoration-none text-dark pointer text-description-2"
                href="https://www.facebook.com/edificor/" 
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => QuantityClicks("https://www.facebook.com/edificor/" )}>
              <img src={Facebook}/>
            </a>
          </div>
          <div className="col-3 col-sm-2 col-md-1 col-lg-1 text-center">
            <a className="text-decoration-none text-dark pointer text-description-2"
                href="https://api.whatsapp.com/send/?phone=5493513052700&text&app_absent=0" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() => QuantityClicks("https://api.whatsapp.com/send/?phone=5493513052700&text&app_absent=0")}>
              <img src={Whatsapp}/>
            </a>
          </div>
          <div className="col-3 col-sm-2 col-md-1 col-lg-1 text-start">
            <a className="text-decoration-none text-dark text-description-2"
                href="https://www.instagram.com/edificor/?hl=es" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() => QuantityClicks("https://www.instagram.com/edificor/?hl=es")}>
              <img src={Instagram}/>
            </a>
          </div>
          {/* <div className="col-3 col-sm-2 col-md-2 col-lg-1 text-center">
            <a className="text-decoration-none text-dark pointer text-description-2"
                href="https://edificor.com.ar/" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() => QuantityClicks("https://edificor.com.ar/")}>
                
            </a>
          </div> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
