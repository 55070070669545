import React from "react";
import LOGOEDIF from "../../../IMAGES/Recurso 14b.png";
import FormatDayandMonthAndyear from "../../../CurstomHooks/FormatDayandMonthAndYear";

const ComponentToPrint = ({
  items,
  componentRef,
  quantityMarks,
  quantityClicks,
}) => {
  const currentDate = FormatDayandMonthAndyear(new Date());

  const toPercent = (data) => {
    var percent = Number((data / 100)* 100);
    percent = Math.round(percent)
    percent += "%";
    return percent;
  };

  return (
    <>
      <div className="container w-pdf" ref={componentRef}>
        <div className="row justify-content-center">
          <img className="w-50 text-center" src={LOGOEDIF}></img>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 my-2">
            <h1 className="display-5 text-center">Metricas Historicas</h1>
          </div>
          <div className="col-4 text-center">
            <h4 className="display-6 fs-5">Total Marcas: {quantityMarks}</h4>
          </div>
          <div className="col-4 text-center">
            <h2 className="display-6 fs-5">Total Clicks: {quantityClicks}</h2>
          </div>
          <div className="col-4 text-center">
            <h3 className="display-6 fs-5">Fecha: {currentDate}</h3>
          </div>
        </div>
        <div className="row pb-5">
          <table className="table table-bordered border-dark mt-3">
            <thead className="mt-5">
              <tr>
                <th className="col-3 text-center bg-gray display-6 fs-5 text-white fw-bolder">
                  Marca
                </th>
                <th className="col-3 text-center bg-gray display-6 fs-5 text-white fw-bolder">
                  Porcentaje
                </th>
                <th className="col-3 text-center bg-gray display-6 fs-5 text-white fw-bolder">
                  Cantidad de Clicks
                </th>
              </tr>
            </thead>
            <tbody>
              {items
                ? items.map((data, index) => (
                    <>
                      <tr key={index}>
                        <td className="text-center fw-bold">{data.marca}</td>
                        <td className="text-center">{toPercent(data.porcentaje)}</td>
                        <td className="text-center">{data.clicks}</td>
                      </tr>
                    </>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ComponentToPrint;
