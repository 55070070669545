import React, {useState, useEffect, useRef} from 'react'
import BorderLinearProgress from "../../BorderLinearProgress"
import Receipt from "./Receipt"

const TableMetrics = ({items, typeOfTheMetric}) => {
    const [item, setItem] = useState(items);
    const [resultSearch, setResultSearch] = useState("");
    const [quantityClicks, setQuantityClicks] = useState("")
    const [quantityMarks, setQuantityMarks] = useState("")
    const componentRef = useRef();

    const getTotalClicks = () => {
        let totalClicks = 0

        item.forEach((a) => {
            totalClicks += a.clicks
        })
        setQuantityClicks(totalClicks)
    }

    const getTotalMarks = () => {
        let totalMarks = 0
        items.map((a, index) => {
            totalMarks =  index + 1
        })
        setQuantityMarks(totalMarks)
    }

    const handleSearch = (event) => {
        const search = event.target.value.toString();
        if (search !== "") {
          const results = item.filter((item) =>
            item.marca.includes(search.toLowerCase())
          );
          setResultSearch(results);
          setItem(results);
        } else {
          setResultSearch(items);
          setItem(orderByPlus);
        }
      };
      
      const orderByLess = () => {
        const results = items.sort((a, b) => {
          if (a.clicks < b.clicks) {
            return -1;
          }
          if (a.clicks > b.clicks) {
            return 1;
          }
        });
        setItem([...results]);
        setResultSearch([...results]);
      };
    
      const orderByPlus = () => {
        const results = items.sort((a, b) => {
          if (a.clicks > b.clicks) {
            return -1;
          }
          if (a.clicks < b.clicks) {
            return 1;
          }
        });
        setItem([...results]);
        setResultSearch([...results]);
      };

      useEffect(() => {
        {resultSearch === "" && setResultSearch(orderByPlus)}
        getTotalClicks()
        getTotalMarks()
      }, []);

    return (
        <div>
          <div className="row mx-2 rounded text-center justify-content-center my-3">
            <div className='col-9 col-lg-7'>
              <h1 className="text-end py-5 display-5">{typeOfTheMetric}</h1>
            </div>
            <div className='col-3 col-lg-4 text-start py-5 display-6'>
              <Receipt items={items} quantityClicks={quantityClicks} quantityMarks={quantityMarks}/>
            </div>
            <p className='col-6 fs-5'>Total Marcas: {quantityMarks}</p>
            <p className='col-6 fs-5'>Total Clicks: {quantityClicks}</p>
              <div className="col-12 col-lg-10 text-center">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Buscar metrica"
                  onChange={handleSearch}
                />
              </div>
                <div className="dropdown col-lg-2 my-2 my-lg-0 text-start">
                  <a
                    className="btn btn-dark dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ordenar por
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a className="dropdown-item pointer" onClick={orderByPlus}>
                        Mas a Clicks
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item pointer" onClick={orderByLess}>
                        Menos Clicks
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
    
            <div className="container-fluid rounded pt-3">
              <div className="row">
                <div className="row mx-2 justify-content-center border bg-header mb-3">
                  <div className="col-4 col-lg-3 text-center mt-2">
                    <p className="text-header">Marcas</p>
                  </div>
                  <div className="col-4 col-lg-6 text-center mt-2">
                    <p className="text-header">Porcentaje</p>
                  </div>
                  <div className="col-4 col-lg-3 text-center mt-2">
                    <p className="text-header">Clicks</p>
                  </div>
                </div>
              </div>
              <div className="row">
                {resultSearch
                  ? resultSearch.map((data, index) => (
                      <div
                        className="row mx-2 justify-content-center bgHover"
                        key={index}
                      >
                        <div className="col-4 col-lg-3 wh-box-header text-center mb-4 pt-1">
                          <p className="text-marca">{data.marca.toUpperCase()}</p>
                        </div>
                        <div className="col-6 col-lg-6 wh-box py-1">
                          <BorderLinearProgress
                            variant="determinate"
                            value={
                              data.porcentaje !== "NaN"
                                ? Number(data.porcentaje)
                                : 0
                            }
                          />
                        </div>
                        <div className="col-2 col-lg-3 wh-box-header text-center">
                          <p className="text-clicks">{data.clicks}</p>
                        </div>
                      </div>
                    ))
                  : "No hay metricas"}
              </div>
            </div>
        </div>
      );
}

export default TableMetrics