import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useForm from "../../CurstomHooks/useForm";
import { URL} from "../../URL";

const Login = () => {
  const [error, setError] = useState("");
  const { form, handleChange } = useForm();
  const navigate = useNavigate();
  const urlLogin = `${URL}/login`;
  
  const sendForm = (e) => {
    e.preventDefault();
    if (form.passLogin !== "" && form.userLogin !== "") {
      const header = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user: form.userLogin, pass: form.passLogin }),
      };
      fetch(urlLogin, header)
        .then((data) => data.json())
        .then((res) => {
          if (res[0].tipo_usuario === "ADMIN") {
            localStorage.setItem("User", JSON.stringify(res[0]));
            navigate("/dashboard/metricas-diarias");
          }
          if (res[0].Error) {
            setError(res[0].Error);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div>
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header bg-white">
                      <h3 className="text-center font-weight-light my-4">
                        Login
                      </h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-floating mb-3">
                          <input
                            name="userLogin"
                            className="form-control"
                            id="inputEmail"
                            type="text"
                            onChange={handleChange}
                          />
                          <label htmlFor="inputEmail">Nombre de usuario</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            name="passLogin"
                            id="inputPassword"
                            type="password"
                            onChange={handleChange}
                          />
                          <label htmlFor="inputPassword">Contraseña</label>
                        </div>
                        <div className="d-flex-row text-center align-items-center justify-content-between mt-4 mb-0">
                          <button
                            className="col-4 btn btn-dark"
                            onClick={sendForm}
                          >
                            Ingresar
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center py-4 bg-white">
                      {error ? (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Login;
