import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintResultsDaily from "./PrintResultsDaily";
import { BsFileEarmarkPdf } from "react-icons/bs"

const ReceiptDaily = ({ items, quantityClicks, quantityMarks }) => {
  const componentRef = useRef();
  return (
    <div>
      <ReactToPrint
        trigger={() => <BsFileEarmarkPdf className="pointerPdf"/>}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <PrintResultsDaily
          className="print-preview"
          componentRef={componentRef}
          items={items}
          quantityMarks={quantityMarks}
          quantityClicks={quantityClicks}
        />
      </div>
    </div>
  );
};
export default ReceiptDaily;