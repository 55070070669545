import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/js/dist/collapse";
import "../node_modules/bootstrap/js/dist/dropdown";
import "../node_modules/bootstrap/js/dist/carousel";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import NavBar from "./Components/NavBar";
import MonthlyMetrics from "./Components/Dashboard/MonthlyMetrics";
import HistoryMetrics from "./Components/Dashboard/HistoryMetrics";
import { ProtectedRoute } from "./Components/ProtectedRouteAdmin";
import { ProtectedRouteClient } from "./Components/ProtectedRouteClient";
import FormLogin from "./Components/FormLogin";
import QR from "./Components/QR";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/:idQR" element={<QR/>} />
        <Route path="/" element={<FormLogin />} exact={true} />
        <Route
          path="/home"
          element={
            <ProtectedRouteClient>
              <Home />
            </ProtectedRouteClient>
          }
          exact={true}
        />
        <Route path="/dashboard" element={<Login />} exact={true} />
        <Route
          path="/dashboard/metricas-diarias"
          element={
            <ProtectedRoute>
              <NavBar>
                <Dashboard />
              </NavBar>
            </ProtectedRoute>
          }
          exact={true}
        />
        <Route
          path="/dashboard/metricas-mensuales"
          element={
            <ProtectedRoute>
              <NavBar>
                <MonthlyMetrics />
              </NavBar>
            </ProtectedRoute>
          }
          exact={true}
        />
        <Route
          path="/dashboard/metricas-historicas"
          element={
            <ProtectedRoute>
              <NavBar>
                <HistoryMetrics />
              </NavBar>
            </ProtectedRoute>
          }
          exact={true}
        />
        <Route path="/Login" element={<Login />} exact={true} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
