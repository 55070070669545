import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { isAuthenticated } from '../UrlFetch'

export const ProtectedRoute = ({children}) => {
    const navigate = useNavigate()
    if(localStorage.getItem('User')){
        const isAuthentic = () => {
            const token = {token: JSON.parse(localStorage.getItem('User'))[0].token}
            const headerPost = {method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(token)};
             fetch(isAuthenticated, headerPost)
              .then((response) => response.json())
              .then((response) => response)
              .catch((err) => console.error(err))
        }
        return children
    }else{
        return <Navigate to="/dashboard"/>
    }
}
