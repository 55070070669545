import React, {useEffect, useState } from "react";
import "./styles.css";
import {BsList, BsGraphUp} from "react-icons/bs"
import { Link } from "react-router-dom";
import {BiLogOut} from "react-icons/bi"
import { IoIosAddCircleOutline } from "react-icons/io"
import { FiEdit } from "react-icons/fi"
import {RiDeleteBinLine} from "react-icons/ri"
import { AiOutlineDownload } from "react-icons/ai"
import * as XLSX from 'xlsx/xlsx.mjs';
import { datosclientes } from "../UrlFetch";

const NavBar = ({ children }) => {
    const [dataClient, setDataCliente] = useState("")
    const [isActiveNav, setIsActiveNav] = useState("d-none")
    const sideBarToggler = () =>{
        if(isActiveNav !== "d-none"){
            setIsActiveNav("d-none")
        }else{
            setIsActiveNav("sb-sidenav accordion sb-sidenav-dark")
        }
    }

    const Logout = () => {
      localStorage.clear()
      window.location.href = "/dashboard"
    }

    const getClients = () => {
      const header = {method: "GET", headers: { "Content-Type": "application/json" }}
      fetch(datosclientes, header)
      .then(data => data.json())
      .then(res => {
        setDataCliente(res)
      })
      .catch(err => console.log(err))

    }

    useEffect(()=>{
      getClients()
    },[])

    const exportExel = () => {
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(dataClient)
      XLSX.utils.book_append_sheet(wb, ws, "Pagina 1")
      XLSX.writeFile(wb, "DATOS-CLIENTES.xlsx")
    }

  return (
    <div className="sb-nav-fixed">
      <nav className="sb-topnav navbar navbar-expand-lg navbar-dark bg-dark justify-content-start">
        <button className="btn btn-dark btn-sm order-1 order-lg-0 me-4 me-lg-0 mx-2" 
          onClick={sideBarToggler}
          
          id="sidenavAccordion">
          <BsList className="fs-2"/>
        </button>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav
            className={isActiveNav}
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu">
              <div className="nav">
                <div className="sb-sidenav-menu-heading">Metricas</div>
                <Link className="nav-link" to="/dashboard/metricas-diarias" onClick={() => setIsActiveNav("d-none")}>
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-tachometer-alt"></i>
                  </div>
                  <BsGraphUp className="fs-4 mx-1"/>Metricas Diarias
                </Link>
                <Link className="nav-link" to="/dashboard/metricas-mensuales" onClick={() => setIsActiveNav("d-none")}>
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-tachometer-alt"></i>
                  </div>
                  <BsGraphUp className="fs-4 mx-1"/>Metricas Mensuales
                </Link>
                <Link className="nav-link" to="/dashboard/metricas-historicas" onClick={() => setIsActiveNav("d-none")}>
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-tachometer-alt"></i>
                  </div>
                  <BsGraphUp className="fs-4 mx-1"/>Metricas Historicas
                </Link>
                <div className="sb-sidenav-menu-heading">
                  Administrar Marcas
                </div>
                <a
                  className="nav-link collapsed pointer"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#collapseLayouts"
                  // aria-expanded="false"
                  // aria-controls="collapseLayouts"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-columns"></i>
                  </div>
                    <IoIosAddCircleOutline className="fs-4 mx-1"/> Añadir
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <a className="nav-link collapsed pointer">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-book-open"></i>
                  </div>
                  <FiEdit className="fs-4 mx-1"/>Editar
                  <div className="sb-sidenav-collapse-arrow"></div>
                </a>
                <a className="nav-link collapsed pointer">
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-book-open"></i>
                  </div>
                  <RiDeleteBinLine className="fs-4 mx-1"/>Eliminar
                  <div className="sb-sidenav-collapse-arrow"></div>
                </a>
                <div className="sb-sidenav-menu-heading">
                  Datos Clientes
                </div>
                <a className="nav-link collapsed pointer" onClick={exportExel}>
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-book-open"></i>
                  </div>
                  <AiOutlineDownload className="fs-4 mx-1"/>Descargar
                  <div className="sb-sidenav-collapse-arrow"></div>
                </a>
                <div
                  className="collapse"
                  id="collapsePages"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#sidenavAccordion"
                ></div>
              </div>
            </div>
            <div className="sb-sidenav-footer pointer hoverWhite">
              <button className="btn text-white" onClick={Logout}><BiLogOut className="mb-1"/> Cerrar Sesion</button>
            </div>
          </nav>
        </div>
      </div>
      <div id="layoutSidenav_content">{children}</div>
    </div>
  );
};

export default NavBar;
