import React, { useState } from "react";
//PRIMER ITEM=======================================
import Roca from "../../../IMAGES/Recurso 9.png";
import Portobello from "../../../IMAGES/Recurso 19.png";
import Sika from "../../../IMAGES/Recurso 10.png";
import Atrim from "../../../IMAGES/Recurso 27.png";
//---HOVER---
import Roca2 from "../../../IMAGES/Recurso 9b.png";
import Portobello2 from "../../../IMAGES/Recurso 19b.png";
import Sika2 from "../../../IMAGES/Recurso 10b.png";
import Atrim2 from "../../../IMAGES/Recurso 27b.png";

//SEGUNDO ITEM=======================================
import AceroLatina from "../../../IMAGES/Recurso 6.png";
import Fv from "../../../IMAGES/Recurso 20.png";
import Conbell from "../../../IMAGES/Recurso 3.png";
import Zedra from "../../../IMAGES/Recurso 26.png";
//---HOVER---
import AceroLatina2 from "../../../IMAGES/Recurso 6b.png";
import Fv2 from "../../../IMAGES/Recurso 20b.png";
import Conbell2 from "../../../IMAGES/Recurso 3b.png";
import Zedra2 from "../../../IMAGES/Recurso 26b.png";

//TERCER ITEM=======================================
import SmartGlass from "../../../IMAGES/Recurso 8.png";
import Aluglass from "../../../IMAGES/Recurso 28.png";
import PiedraFina from "../../../IMAGES/Recurso 29.png";
import GlassTecnology from "../../../IMAGES/Recurso 7.png";
//---HOVER---
import SmartGlass2 from "../../../IMAGES/Recurso 8b.png";
import Aluglass2 from "../../../IMAGES/Recurso 28b.png";
import PiedraFina2 from "../../../IMAGES/Recurso 29b.png";
import GlassTecnology2 from "../../../IMAGES/Recurso 7b.png";

import QuantityClicks from "../../../CurstomHooks/QuantityClicks";

const Carrousel = () => {
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image7, setImage7] = useState("");
  const [image8, setImage8] = useState("");
  const [image9, setImage9] = useState("");
  const [image10, setImage10] = useState("");
  const [image11, setImage11] = useState("");
  const [image12, setImage12] = useState("");

  return (
    <div className="container-fluid mt-5 border-top border-dark">
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide py-5"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            className="active"
            aria-current={true}
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2500">
            {/* PRIMERA FILA DE IMAGENES */}
            <div className="row py-sm-2 py-lg-3 text-center justify-content-center">
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Sanitarios</p>
                <a
                  rel="noopener noreferrer"
                  href="https://www.roca.com.ar/"
                  target="_blank"
                  onClick={() => QuantityClicks("https://www.roca.com.ar/")}
                  onMouseDown={() => QuantityClicks("https://www.roca.com.ar/")}
                >
                  <img
                    src={!image1 ? Roca : image1}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage1(Roca2)}
                    onMouseLeave={() => setImage1(Roca)}
                    alt="logo Roca"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Pisos y Revestimientos</p>
                <a
                  href="https://www.portobello.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://www.portobello.com.br/")}
                  onMouseDown={() => QuantityClicks("https://www.portobello.com.br/")}
                >
                  <img
                    src={!image2 ? Portobello : image2}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage2(Portobello2)}
                    onMouseLeave={() => setImage2(Portobello)}
                    alt="logo portobello"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Pegamentos y Pastinas</p>
                <a
                  href="https://arg.sika.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://arg.sika.com/")}
                  onMouseDown={() => QuantityClicks("https://arg.sika.com/")}
                >
                  <img
                    src={!image3 ? Sika : image3}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage3(Sika2)}
                    onMouseLeave={() => setImage3(Sika)}
                    alt="logo sika"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Perfiles y Terminaciones</p>
                <a
                  href="https://www.atrimglobal.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://www.atrimglobal.com.ar/")}
                  onMouseDown={() => QuantityClicks("https://www.atrimglobal.com.ar/")}
                >
                  <img
                    src={!image4 ? Atrim : image4}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage4(Atrim2)}
                    onMouseLeave={() => setImage4(Atrim)}
                    alt="logo atrim"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* SEGUNDA FILA DE IMAGENES */}
          <div className="carousel-item" data-bs-interval="2500">
            <div className="row py-sm-2 py-lg-3 text-center justify-content-center">
              {/* VEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEER */}
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Revestimiento Exterior</p>
                <a
                  href="https://linktr.ee/grupoltn.ok?utm_source=linktree_profile_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://linktr.ee/grupoltn.ok?utm_source=linktree_profile_share")}
                  onMouseDown={() => QuantityClicks("https://linktr.ee/grupoltn.ok?utm_source=linktree_profile_share")}
                >
                  <img
                    src={!image5 ? AceroLatina : image5}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage5(AceroLatina2)}
                    onMouseLeave={() => setImage5(AceroLatina)}
                    alt="logo AceroLatina"
                  />
                </a>
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Griferias</p>
                <a
                  href="https://fvsa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://fvsa.com/")}
                  onMouseDown={() => QuantityClicks("https://fvsa.com/")}
                >
                  <img
                    src={!image6 ? Fv : image6}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage6(Fv2)}
                    onMouseLeave={() => setImage6(Fv)}
                    alt="logo fv"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Equipamiento Fino</p>
                <a
                  href="https://instagram.com/conbell_?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://instagram.com/conbell_?igshid=NTc4MTIwNjQ2YQ==")}
                  onMouseDown={() => QuantityClicks("https://instagram.com/conbell_?igshid=NTc4MTIwNjQ2YQ==")}
                >
                  <img
                    src={!image7 ? Conbell : image7}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage7(Conbell2)}
                    onMouseLeave={() => setImage7(Conbell)}
                    alt="logo Conbell"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Amoblamiento</p>
                <a
                  href="https://zedra.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://zedra.com.ar/")}
                  onMouseDown={() => QuantityClicks("https://zedra.com.ar/")}
                >
                  <img
                    src={!image8 ? Zedra : image8}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage8(Zedra2)}
                    onMouseLeave={() => setImage8(Zedra)}
                    alt="logo zedra"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* TERCERA FILA DE IMAGENES */}
          <div className="carousel-item" data-bs-interval="2500">
            <div className="row py-5 py-sm-3 text-center justify-content-center">
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Smart Glass</p>
                <a
                  href="https://linktr.ee/garciarecta_d?utm_source=linktree_profile_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://linktr.ee/garciarecta_d?utm_source=linktree_profile_share")}
                  onMouseDown={() => QuantityClicks("https://linktr.ee/garciarecta_d?utm_source=linktree_profile_share")}
                >
                  <img
                    src={!image9 ? SmartGlass : image9}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage9(SmartGlass2)}
                    onMouseLeave={() => setImage9(SmartGlass)}
                    alt="logo SmartGlass"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Aberturas</p>
                <a
                  href="https://www.instagram.com/aluglass_"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://www.instagram.com/aluglass_")}
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/aluglass_")}
                >
                  <img
                    src={!image10 ? Aluglass : image10}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage10(Aluglass2)}
                    onMouseLeave={() => setImage10(Aluglass)}
                    alt="logo ALUGLASS"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel pointer">
                  Revestimientos de Piedra
                </p>
                <a
                  href="https://www.instagram.com/piedrafina_arg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://www.instagram.com/piedrafina_arg/")}
                  onMouseDown={() => QuantityClicks("https://www.instagram.com/piedrafina_arg/")}
                >
                  <img
                    src={!image11 ? PiedraFina : image11}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage11(PiedraFina2)}
                    onMouseLeave={() => setImage11(PiedraFina)}
                    alt="logo PiedraFina"
                  />
                </a>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <p className="text-carrousel">Glass Tecnology</p>
                <a
                  href="https://linktr.ee/idrix_?utm_source=linktree_profile_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => QuantityClicks("https://linktr.ee/idrix_?utm_source=linktree_profile_share")}
                  onMouseDown={() => QuantityClicks("https://linktr.ee/idrix_?utm_source=linktree_profile_share")}
                >
                  <img
                    src={!image12 ? GlassTecnology : image12}
                    className="img-carrousel pointer"
                    onMouseEnter={() => setImage12(GlassTecnology2)}
                    onMouseLeave={() => setImage12(GlassTecnology)}
                    alt="logo GlassTecnology"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev  w-btn-carrousel"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon mr-carrousel-5"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next w-btn-carrousel"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon ml-carrousel-5"
            aria-hidden={true}
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carrousel;
