import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useForm from "../../CurstomHooks/useForm";
import FormatDayandMonthAndYear from "../../CurstomHooks/FormatDayandMonthAndYear";
import { guardardatosclientes } from "../UrlFetch";

const FormLogin = () => {
  const [error, setError] = useState("");
  const { form, handleChange } = useForm();
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  const currentDate = FormatDayandMonthAndYear(new Date());

  const sendForm = (e) => {
    e.preventDefault();

    if (!form.emailClient) {
      setIsInvalid(true);
      setError("Correo Invalido");
    } else {
      setIsInvalid(false);
      setError("");
        if (
          form.emailClient !== "" &&
          form.nameClient !== ""
        ) {
          const header = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              nombre: form.nameClient,
              email: form.emailClient,
              fecha: currentDate,
            }),
          };
          fetch(guardardatosclientes, header)
            .then((data) => data.json())
            .then((res) => {
              if (res.mensaje === "Guardado Correctamente") {
                localStorage.setItem("client",JSON.stringify({rol: "cliente"})
                );
                navigate("/home");
              } else {
                navigate("/");
              }
            })
            .catch((err) => console.error(err));
        }
      }
  };

  return (
    <div className="container-fluid casaEnBlanco vh-100">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card border rounded-lg mt-5">
                    <div className="card-header bgLoginClient">
                      <h3 className="text-center font-weight-light my-4">
                        Login
                      </h3>
                    </div>
                    <div className="card-body bgLoginClient">
                      <form onSubmit={sendForm}>
                        <div className="form-floating mb-3">
                          <input
                            name="nameClient"
                            className="form-control"
                            id="inputEmail"
                            type="text"
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="inputEmail">
                            Nombre o Razon Social
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            name="emailClient"
                            className={
                              isInvalid
                                ? `form-control is-invalid`
                                : `form-control`
                            }
                            id="inputEmail"
                            type="text"
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="inputEmail">Email</label>
                        </div>
                        <div className="d-flex-row text-center align-items-center justify-content-between mt-4 mb-0">
                          <button className="col-4 btn bg-gray" type="submit">
                            Ingresar
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center py-4 bgLoginClient">
                      {error ? (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
