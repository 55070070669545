const FormatDayandMonthAndYear = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if(day < 10 && month < 10){
      return `0${day}/0${month}/${year}`
    }else{
      return `${day}/${month}/${year}`
    }
};

export default FormatDayandMonthAndYear