import React, { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import BorderLinearProgress from "../BorderLinearProgress";
import useForm from "../../CurstomHooks/useForm";
import MetricsDatePicker from "./MetricsDatePicker";
import DatePickerMothly from "./DatePickerMothly";
import FormatDayandMonthAndYear from "../../CurstomHooks/FormatDayandMonthAndYear";
import FormatMonthAndYear from "../../CurstomHooks/FormatMonthAndYear";
import ReceiptMonthly from "./MonthlyMetrics/Receipt";
import ReceiptDaily from "./ReceiptDaily"

const TableProgress = ({
  items,
  typeOfTheMetric,
  selectedDate,
  handleChangePiker,
  searchDate,
  resultDate,
  setSelectedDate,
}) => {
  const [item, setItem] = useState(items);
  const [resultSearch, setResultSearch] = useState("");
  const [typeMetric, setTypeMetric] = useState("");
  const [monthSelected, setMonthSelected] = useState("Buscar Mes");
  const [yearSelected, setYearSelected] = useState("Buscar Año");
  const [mothAndYear, setMonthandYear] = useState(selectedDate);
  const [years, setYears] = useState("");
  const [quantityClicks, setQuantityClicks] = useState(0);
  const [quantityMarks, setQuantityMarks] = useState("");

  const month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const getYear = () => {
    let date = new Date();
    let currentYear = date.getFullYear();
    let results = [
      currentYear - 1,
      currentYear,
      currentYear + 1,
      currentYear + 2,
    ];
    setYears(results);
  };

  const getTotalClicks = () => {
    let totalClicks = 0;

    item.forEach((a) => {
      totalClicks += a.clicks;
    });
    setQuantityClicks(totalClicks);
  };

  const getTotalMarks = () => {
    let totalMarks = 0;
    item.map((a, index) => {
      totalMarks = index + 1;
    });
    setQuantityMarks(totalMarks);
  };

  const orderByLess = () => {
    const results = items.sort((a, b) => {
      if (a.clicks < b.clicks) {
        return -1;
      }
      if (a.clicks > b.clicks) {
        return 1;
      }
    });
    setItem([...results]);
    setResultSearch([...results]);
  };

  const orderByPlus = () => {
    const results = items.sort((a, b) => {
      if (a.clicks > b.clicks) {
        return -1;
      }
      if (a.clicks < b.clicks) {
        return 1;
      }
    });
    setItem([...results]);
    setResultSearch([...results]);
  };

  useEffect(() => {
    {
      resultSearch === "" && setResultSearch(orderByPlus);
    }
    getTotalMarks();
    getTotalClicks();
    getYear();
  }, []);

  const handleSearch = (event) => {
    const search = event.target.value.toString();
    if (search !== "") {
      const results = item.filter((item) =>
        item.marca.includes(search.toLowerCase())
      );
      setResultSearch(results);
      setItem(results);
    } else {
      setResultSearch(items);
      setItem(orderByPlus);
    }
  };

  return (
    <div>
      <div className="row mx-2 rounded justify-content-center">
        <div className="col-9 col-lg-8">
          <h1 className="text-end py-5 display-5">{typeOfTheMetric}</h1>
        </div>
        <div className="col-3 col-lg-3 text-start py-5 display-6">
          {typeOfTheMetric ===
            `Metrica Mensual ${FormatMonthAndYear(resultDate)}` && (
            <ReceiptMonthly
              items={items}
              quantityClicks={quantityClicks}
              quantityMarks={quantityMarks}
            />
          )}

          {typeOfTheMetric ===
            `Metrica Diaria ${FormatDayandMonthAndYear(resultDate)}` && (
            <ReceiptDaily
              items={items}
              quantityClicks={quantityClicks}
              quantityMarks={quantityMarks}
            />
          )}
        </div>
        <p className="col-6 col-lg-2 fs-5 text-center">
          Total Marcas: {quantityMarks}
        </p>
        <p className="col-6 col-lg-2 fs-5 text-center">
          Total Clicks: {quantityClicks}
        </p>
        {typeOfTheMetric === "Metricas Historicas" ? (
          <div className="col-10 text-center">
            <input
              className="form-control"
              type="text"
              placeholder="Buscar metrica"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <div className="col-12">
            <input
              className="form-control"
              type="text"
              placeholder="Buscar metrica"
              onChange={handleSearch}
            />
          </div>
        )}

        {/* FILTROS SEGUN TIPO DE METRICA */}
        {typeOfTheMetric === "Metricas Historicas" && ""}

        {typeOfTheMetric ===
        `Metrica Diaria ${FormatDayandMonthAndYear(resultDate)}` ? (
          <div className="row my-3">
            <div className="col-5 col-lg-2">
              <MetricsDatePicker
                selectedDate={selectedDate}
                handleChangePiker={handleChangePiker}
              />
            </div>

            <div className="col-2 col-lg-1 text-end">
              <button
                className="btn btn-dark pointer"
                onClick={() => searchDate()}
              >
                Buscar
              </button>
            </div>
            <div className="col-2 col-lg-8 text-end mx-4 mx-lg-4">
              <div className="dropdown">
                <a
                  className="btn btn-dark dropdown-toggle"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Filtrar por
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item pointer" onClick={orderByPlus}>
                      Mas a Clicks
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item pointer" onClick={orderByLess}>
                      Menos Clicks
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {typeOfTheMetric ===
        `Metrica Mensual ${FormatMonthAndYear(resultDate)}` ? (
          <div className="row rounded my-3">
            <div className="dropdown col-2 col-lg-1 mx-3 mx-lg-4">
              <button
                className="btn btn-dark dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {monthSelected}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {month.length
                  ? month.map((month, index) => (
                      <li
                        key={index}
                        onClick={() => setMonthSelected(`0${index + 1}`)}
                      >
                        <a
                          className="dropdown-item py-1 pointer"
                          onClick={() => setMonthSelected(`0${index + 1}`)}
                        >
                          {month}
                        </a>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
            <div className="dropdown col-2 col-lg-1 mx-5 mx-lg-3">
              <button
                className="btn btn-dark dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {yearSelected}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {years.length
                  ? years.map((year, index) => (
                      <li key={index} onClick={() => setYearSelected(year)}>
                        <a
                          className="dropdown-item py-1 pointer"
                          onClick={() => setYearSelected(year)}
                        >
                          {year}
                        </a>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>

            <div className="col-2 col-lg-1 text-end">
              <button
                className="btn btn-dark pointer"
                onClick={() => searchDate(`${monthSelected}/${yearSelected}`)}
              >
                Buscar
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* FINAL FILTROS SEGUN TIPO DE METRICA */}

        <div className="container-fluid rounded">
          <div className="row">
            <div className="row mx-2 justify-content-center border rounded-pill bg-header mb-3">
              <div className="col-4 col-lg-3 wh-box-header text-center mt-2">
                <p className="text-header">Marca</p>
              </div>
              <div className="col-4 col-lg-6 wh-box-header text-center mt-2">
                <p className="text-header">Porcentaje</p>
              </div>
              <div className="col-4 col-lg-3 wh-box-header text-center mt-2">
                <p className="text-header">Clicks</p>
              </div>
            </div>
          </div>
          <div className="row">
            {resultSearch
              ? resultSearch.map((data, index) => (
                  <div
                    className="row mx-2 justify-content-center bgHover"
                    key={index}
                  >
                    <div className="col-4 col-lg-3 wh-box-header text-center mb-4 pt-1">
                      <p className="text-marca">{data.marca.toUpperCase()}</p>
                    </div>
                    <div className="col-6 col-lg-6 wh-box py-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          data.porcentaje !== "NaN"
                            ? Number(data.porcentaje)
                            : 0
                        }
                      />
                    </div>
                    <div className="col-2 col-lg-3 wh-box-header text-center">
                      <p className="text-clicks">{data.clicks}</p>
                    </div>
                  </div>
                ))
              : "No hay metricas"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableProgress;
