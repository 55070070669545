import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

export const ProtectedRouteClient = ({children}) => {
    const navigate = useNavigate()

    if(localStorage.getItem('client')){
        return children
    }else{
        return <Navigate to="/"/>
    }
}