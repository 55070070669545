import { click } from '@testing-library/user-event/dist/click'
import React,{useEffect, useState} from 'react'
import { totalclicks } from '../../UrlFetch'
import TableMetrics from './TableMetrics.js'

const HistoryMetrics = () => {
  const [items, setItems] = useState("")
  const [selectedDate, setSelectedDate] = useState("")

  const getData = () => {
    const header = { method: "GET", headers: { "Content-Type": "application/json" }};
    
    fetch(totalclicks,header)
    .then((res) => res.json())
    .then((data) => setItems(data))
    .catch((err) => console.error(err));
  }
  
  const typeOfTheMetric = "Metricas Historicas"

  useEffect(()=>{
    getData()
  },[])

  const handleChangePiker = (date) => { 
    setSelectedDate("")
    setSelectedDate(date)
}

const searchDate = () =>{
  setItems([])
  setSelectedDate(selectedDate);
  getData()
}

const keyValue = items.length

  return (
    <div className="container pt-3 pt-lg-5">
      {items  ?  (<TableMetrics
          items={items}
          typeOfTheMetric={typeOfTheMetric}
          key={keyValue}
        />): ""}
    </div>
  )
}

export default HistoryMetrics