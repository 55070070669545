import React from "react";
import DatePiker from "react-datepicker";

const MetricsDatePicker = ({selectedDate, handleChangePiker}) => {
  return (
        <DatePiker
          className="form-control"
          selected={selectedDate}
          closeOnScroll={(e) => e.target === document}
          onChange={handleChangePiker}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          withPortal
          portalId="root-portal"
        />
  );
};

export default MetricsDatePicker;
