import React, {useState} from 'react'
import LOGO from "../../IMAGES/LOGO-PNG.png"
import Edificor from "../../IMAGES/Recurso 14.png";
import Edificor2 from "../../IMAGES/Recurso 14b.png";
import BAUVEK from "../../IMAGES/Recurso 22.png";
import BAUVEK2 from "../../IMAGES/Recurso 22b.png";
import NURPANEL from "../../IMAGES/Recurso 21.png";
import NURPANEL2 from "../../IMAGES/Recurso 21b.png";
import MAG from "../../IMAGES/Recurso 23.png";
import MAG2 from "../../IMAGES/Recurso 23b.png";
import TEXTO from "../../IMAGES/RECURSO-TEXTO.png";
import Carrousel from "./Carrousel";
import CarrouselDos from "./CarrouselDos";
import Footer from "../Footer";
import QuantityClicks from "../../CurstomHooks/QuantityClicks";

const  Home= () => {
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");

  return (
    <>
      <div className="container-fluid casaEnBlanco vh-100">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-8 col-sm-5 col-md-4 col-lg-3 logo-png animationSpawn">
            <img src={LOGO} className="logo-png col-12" alt="logo casa en blanco"></img>
            <br />
            <br />
            <img src={TEXTO} className="logo-png col-12" alt="espacio de experimentacion"></img>
          </div>
        </div>
      </div>
      <div className="container-fluid vh-100-casa">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9 m-5">
            <p className="title-edificor">
              Un lugar de inspiración para quienes están diseñando una casa.
            </p>
            <p className="text-description-edif">
              Casa en Blanco es un nuevo proyecto de Edificor, un espacio de
              experimentación, un laboratorio de tendencias de la arquitectura y
              el diseño. Una casa desarrollada en el ingreso de nuestro showroom
              con las últimas tendencias en construcción del mercado.
            </p>
            <p className="text-description-2">
              Más que ver nuestros productos, vas a poder experimentarlos y
              soñarlos en tu hogar.
            </p>
          </div>
        </div>
        <div className="row justify-content-center bg-white pb-lg-5">
          <a
            href="https://edificor.com.ar/"
            className="col-10 col-sm-6 col-md-5 col-lg-3 col-xl-2 mt-lg-5 pointer"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => QuantityClicks("https://edificor.com.ar/")} onMouseDown={() => QuantityClicks("https://edificor.com.ar/")}
          >
            <img
              src={!image1 ? Edificor : image1}
              onMouseEnter={() => setImage1(Edificor2)}
              onMouseLeave={() => setImage1(Edificor)}
              alt="logo edificor"
            ></img>
          </a>
        </div>
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-4 h-card">
            <div>
              <p className="text-description-3 mt-lg-4">
                Constructora
              </p>
            </div>
            <a
              href="https://bauvekconstrucciones.com.ar"
              target="_blank"
              className="col-6 col-md-3 col-lg-3 col-xl-3 pt-lg-1 mt-1 mb-lg-2 pointer"
              rel="noopener noreferrer"
              onClick={() => QuantityClicks("https://bauvekconstrucciones.com.ar")}
              onMouseDown={() => QuantityClicks("https://bauvekconstrucciones.com.ar")}
            >
              <img
                src={`${!image2 ? BAUVEK : image2}`}
                onMouseEnter={() => setImage2(BAUVEK2)}
                onMouseLeave={() => setImage2(BAUVEK)}
                alt="logo BAUVEK"
              />
            </a>
          </div>
          <div className="col-12 col-sm-10 col-md-12 col-lg-9 col-xl-4 h-card mt-lg-1">
            <div>
              <p className="text-description-3 mt-lg-4">
                Paneles Estructurales
              </p>
            </div>
            <a
              className="col-6 col-md-10 col-lg-1 pt-lg-12 col-xl-2 pb-5 mb-5 pointer"
              href="https://steelplex.com.ar/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => QuantityClicks("https://steelplex.com.ar/")}
              onMouseDown={() => QuantityClicks("https://steelplex.com.ar/")}
            >
              <img
                src={!image3 ? NURPANEL : image3}
                onMouseEnter={() => setImage3(NURPANEL2)}
                onMouseLeave={() => setImage3(NURPANEL)}
                alt="logo Nurpanel"
              />
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-9 col-xl-4  h-card mt-1">
            <div>
              <p className="text-description-3 mt-lg-4">Arquitectura</p>
            </div>
            <a
              className="col-6 col-md-6 col-lg-2 pt-lg-2 pb-5 mb-5 pointer"
              href="https://www.instagram.com/magstudiomg/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => QuantityClicks("https://www.instagram.com/magstudiomg/")}
              onMouseDown={() => QuantityClicks("https://www.instagram.com/magstudiomg/")}
            >
              <img
                src={!image4 ? MAG : image4}
                onMouseEnter={() => setImage4(MAG2)}
                onMouseLeave={() => setImage4(MAG)}
                alt="logo MAG"
              />
            </a>
          </div>
        </div>
      </div>
      <Carrousel />
      <CarrouselDos />
      {    
        //  
      }
      <div className='container-fluid my-5'>
        <div className='row justify-content-center'>
          <iframe 
              className='col-12 col-lg-8'
              width="auto" 
              height="500" 
              src="https://www.youtube.com/embed/XXOzkAbosdw?autoplay=1&mute=0&loop=1" 
              title="Presentacion Casa en Blanco" 
              frameborder="0" 
              allow="autoplay" 
              allowfullscreen
              ></iframe>
        </div>
      </div>
      
      <Footer />
    </>
  )
}
export default Home