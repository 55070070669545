import React, {useState, useEffect} from 'react'
import TableProgress from '../TableProgress'
import FormatDayandMonthAndYear from '../../../CurstomHooks/FormatDayandMonthAndYear'
import FormatMonthAndYear from '../../../CurstomHooks/FormatMonthAndYear'
import { URL } from '../../../URL'

const MonthlyMetrics= () => {
  const [items, setItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const[resultDate, setResultDate] = useState(new Date)
  const [isActiveNav, setIsActiveNav] = useState("d-none")

  let urlData =  `${URL}/casaenblanco/totalclicks/${FormatMonthAndYear(selectedDate)}`

  const getDateMothlyMetrics = () => {
    let header = {method: "GET", headers: { "Content-Type": "application/json" }};
    fetch(urlData,header)
    .then((res) => res.json())
    .then((data) => setItems(data))
    .catch((err) => console.error(err));
  }

  let typeOfTheMetric  = `Metrica Mensual ${FormatMonthAndYear(new Date())}`
  
  useEffect( () => {
    getDateMothlyMetrics()
  },[])

  const handleChangePiker = (date) => { 
    setSelectedDate("")
    setSelectedDate(date)
  }

  const searchDate = (date) =>{
    urlData =  `${URL}/casaenblanco/totalclicks/${date}`
    getDateMothlyMetrics()
  }

  const keyValue = items.map((item, index) =>{
    return item.click * index
  })

  return (
    <div className='container pt-3 pt-lg-5'>
        {items  ?  (<TableProgress
          items={items}
          typeOfTheMetric={typeOfTheMetric}
          key={keyValue}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleChangePiker={handleChangePiker}
          searchDate={searchDate}
          resultDate={resultDate}
        />): ""}
    </div>
  )
}

export default MonthlyMetrics
