import FormatDayandMonthAndYear from "./FormatDayandMonthAndYear";
import { guardarclicks } from "../Components/UrlFetch";

const QuantityClicks = (url) => {
    let infoClick = {
        url: url,
        fecha: FormatDayandMonthAndYear(new Date()),
        hora: new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1")
    }

    let header = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infoClick),
    } 

    fetch(guardarclicks, header)
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => console.error(error));
}

export default QuantityClicks