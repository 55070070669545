import React, { useState, useEffect } from "react";
import TableProgress from "./TableProgress";
import { URL } from "../../URL";
import "react-datepicker/dist/react-datepicker.css";
import {BsCalendarWeek} from "react-icons/bs";
import MetricsDatePicker from "./MetricsDatePicker";
import FormatDayandMonthAndYear from "../../CurstomHooks/FormatDayandMonthAndYear"

const Dashboard = () => {
  const [items, setItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const[resultDate, setResultDate] = useState(new Date)

  const getDataOfDay = () => {
    let header = {method: "GET", headers: { "Content-Type": "application/json" }};
    let urlData =  `${URL}/casaenblanco/totalclicks/${FormatDayandMonthAndYear(selectedDate)}`
    
    fetch(urlData,header)
    .then((res) => res.json())
    .then((data) => setItems(data))
    .catch((err) => console.error(err));
  };

  const handleChangePiker = (date) => { 
        setSelectedDate("")
        setSelectedDate(date)
  }

  let typeOfTheMetric = `Metrica Diaria ${FormatDayandMonthAndYear(resultDate)}`;

  useEffect( () => {
    getDataOfDay()
  },[])
  
  const searchDate = () =>{
    setItems([])
    setSelectedDate(selectedDate);
    setResultDate(selectedDate)
    getDataOfDay()
  }

  const keyValue = items.map((item, index) =>{
    return item.click * index
  })

  return (
    <div className="container pt-3 pt-lg-5">
      {items  ?  (<TableProgress
          items={items}
          typeOfTheMetric={typeOfTheMetric}
          key={keyValue}
          selectedDate={selectedDate}
          handleChangePiker={handleChangePiker}
          searchDate={searchDate}
          resultDate={resultDate}
        />): ""}
        
    </div>
  );
};
export default Dashboard;
